<template>
  <div class="prize-box">
    <!-- Left part of the box - Image -->
    <div :style="`background-image: url('${image}')`" class="prize-image"></div>

    <!-- Right part of the box - Texts -->
    <div class="prize-texts">
      <h2 class="prize-title">{{ title }}</h2>
      <p class="prize-subtitle">{{ subtitle }}</p>
      <p class="prize-info">{{ description }}</p>

      <a class="link-main"> {{ linkText }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrizeBox',
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    linkText: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.prize-box {
  border-radius: rem(10);
  background-color: white;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  display: flex;
  padding: rem(24);
}

/** Left part of the box - Image **/
.prize-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: rem(10);
  flex-shrink: 0;
  height: rem(182);
  width: rem(290);
}

/** **/

/** Right part of the box - Texts **/
.prize-texts {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(40);

  .prize-title {
    flex-basis: 100%;
    font-size: rem(20);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(24);
    margin-bottom: rem(8);
  }

  .prize-subtitle {
    flex-basis: 100%;
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(17);
    margin-bottom: rem(12);
  }

  .prize-info {
    flex-basis: 100%;
    margin-bottom: rem(25);
  }
}

/** **/
</style>
